<template>
    <div class="page-product">
        <div class="columns is-multiline">
            <div class="column is-9">
                <figure class="image mb-6">
          <img :src="product.get_image" alt="Product Image">
                </figure>
                <h1 class="title">{{ product.name }}</h1>
                <p>{{ product.description }}</p>
                    </div>
      <div class="column is-3">
        <h2 class="subtitle">Информация</h2>
        <p><strong>Цена: </strong>₽{{ product.price }}</p>
        <div class="field has-addons mt-3">
          <div class="control">
            <button class="button is-dark" @click="addToCart()">Добавить в корзину</button>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
    name: 'Product',
    data() {
        return {
            product: {},
            quantity: 1
    };
    },
    computed: {
        cartItems() {
            return this.$store.getters.cartItems;
        }
        },
    watch: {
        cartItems(newVal) {
            console.log('Cart items updated:', newVal);
        }
    },
    mounted() {
        this.getProduct();
        },
    methods: {
        async getProduct() {
            try {
                this.$store.commit('setIsLoading', true);
                const { category_slug, product_slug } = this.$route.params;
                const response = await axios.get(`/product/products/${category_slug}/${product_slug}`);
                this.product = response.data;
                document.title = `${this.product.name} | Kidread`;
            } catch (error) {
                console.error('Failed to fetch product:', error);
            toast({
                    message: 'Ошибка при загрузке продукта',
                    type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                    duration: 5000,
                position: 'bottom-right',
            });
            } finally {
                this.$store.commit('setIsLoading', false);
}
        },
        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1;
    }
            const item = [{
                product: this.product,
                quantity: this.quantity
            }];

            console.log('Adding to cart:', item); // Debug log

            this.$store.commit('addToCart', item);

            toast({
                message: 'Добавлен в корзину',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
            });
}
    }
}
</script>
