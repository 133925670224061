import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import './../node_modules/bulma/css/bulma.css'

axios.defaults.baseURL = 'https://kidread.online/api/v1/'

createApp(App).use(store).use(router, axios).mount('#app')
