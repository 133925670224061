<template> 
  <div class="shop">
    <div class="columns is-multiline"></div>
    <section class="hero is-small is-dark mb-6">

           <div class="hero-body has-text-centered">
              <p class="title mb-6">
                 Добро пожаловать в наш магазин
              </p>
              <p class="subtitle">
                  Тут представленны наши продукты
              </p>
            </div>
    </section>        

        <div> <ProductBox 
          v-for="product in latestProducts"
          v-bind:key="product.id"
          v-bind:product="product" />
        </div>
      </div> 
</template>
  
  <script>
  import axios from 'axios'

  import ProductBox from '@/components/ProductBox'
  
  export default {

    name: 'Shop',
    data() {
      return {
        latestProducts: []
      }
    },
    components: {
      ProductBox
    },
    mounted() {
      this.getLatestProducts()
  
      document.title = 'Магазин | Kidread'
    },
    methods: {
      async getLatestProducts() {
        this.$store.commit('setIsLoading', true)
  
        await axios
          .get('/product/latest-products/')
          .then(response => {
            this.latestProducts = response.data
          })
          .catch(error => {
            console.log(error)
          })
  
        this.$store.commit('setIsLoading', false)
      },
    }
  }
  </script>