<template>
  <div class="about">
    <div class="hero is-info">
      <div class="hero-body has-text-centered">
        <h1 class="title">О нас</h1>
      </div>
    </div>

    <section class="section">
      Портал изучения английского языка.
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = 'О нас | KidRead.online'
  }
}
</script>