<template>
    <nav class="navbar is-info" role="navigation" aria-label="main naviation" style="min-height: 5rem;">
        <div class="navbar-brand">
            <router-link class="navbar-item is-size-4" to="/">KidRead.online</router-link>
        </div>

        <div id="navbar-item" class="navbar-menu">
            <div class="navbar-start">
                <router-link to="/" class="navbar-item">Домашняя</router-link>
                <router-link to="/about" class="navbar-item">О портале</router-link>
                <router-link to="/courses" class="navbar-item">Курсы</router-link>
                <router-link to="/shop" class="navbar-item">Магазин</router-link>
                <router-link to="/cart" class="navbar-item">Корзина</router-link>
            </div>

            <div class="navbar-end">
                <div class="navbar-item">
                    <div class="buttons">
                        <template v-if="$store.state.user.isAuthenticated">
                            <router-link to="/dashboard/my-account" class="button is-info">Мой профиль</router-link>
                        </template>

                        <template v-else>
                            <router-link to="/sign-up" class="button is-primary"><strong>Зарегистрироваться</strong></router-link>
                            <router-link to="/log-in" class="button is-light">Войти</router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>