<template>
    <div class="column is-3">
        <div class="box">
            <figure class="image mb-4">
                <img :src="product.get_thumbnail" alt="Product image"> <!-- Use a direct property for the thumbnail and add an alt attribute for accessibility -->
            </figure>

            <h3 class="is-size-4">{{ product.name }}</h3>
            <p class="is-size-6 has-text-grey">₽{{ product.price }}</p>

            <router-link :to="product.absoluteUrl" class="button is-light mt-4">Посмотреть детали</router-link> <!-- Use a direct property for the URL -->
            <div class="field has-addons mt-3">
                    <div class="control">
                    <input type="number" class="input" min="1" v-model.number="quantity"> <!-- Ensure the model is treated as a number -->
                    </div>

                    <div class="control">
                    <button class="button is-light" @click="addToCart">Добавить в корзину</button> <!-- Use a button instead of a link for actions -->
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toast } from 'bulma-toast'

export default {
    name: 'ProductBox',
    props: {
        product: Object
    },
    data() {
        return {
            quantity: 1 // Initialize quantity to ensure it's reactive
        };
    },
    methods: {
        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1;
    }
            const item = [{
                product: this.product,
                quantity: this.quantity
            }];

            console.log('Adding to cart:', item); // Debug log

            this.$store.commit('addToCart', item);

            toast({
                message: 'Добавлен в корзину',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
            });
            console.log(`Adding ${this.quantity} of ${this.product.name} to cart.`);
            toast({ message: `${this.product.name} added to cart!`, type: 'is-success' });
}
    }
}
</script>

<style scoped>
  .image {
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
</style>