<template>
    <div class="page-cart">
        <div class="columns is-multiline">
            <div class="column is-12">
        <h1 class="title">Cart</h1>
            </div>

            <div class="column is-12 box">
        <table class="table is-fullwidth" v-if="cartTotalLength > 0">
                    <thead>
                        <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <CartItem
              v-for="item in cartItems"
              :key="item.product.id"
              :initialItem="item"
              @removeFromCart="removeFromCart"
            />
          </tbody>
        </table>
        <p v-else>У вас нет продуктов в корзине...</p>
            </div>

      <div class="column is-12 box">
        <h2 class="subtitle">Total</h2>
        <strong>₽{{ cartTotalPrice.toFixed(2) }}</strong>, {{ cartTotalLength }} items
        <hr>
        <router-link to="/cart/checkout" class="button is-dark">Перейти к оплате</router-link>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CartItem from '@/components/CartItem.vue';

export default {
    name: 'Cart',
    components: {
        CartItem
    },
    computed: {
    ...mapState({
      cart: state => state.cart
    }),
    cartItems() {
      return this.cart.items || [];
        },
    cartTotalLength() {
  if (!this.cartItems || !Array.isArray(this.cartItems)) {
    return 0;
  }
      return this.cartItems.reduce((acc, item) => acc + item.quantity, 0);
        },
    cartTotalPrice() {
      if (!this.cartItems || !Array.isArray(this.cartItems)) {
        return 0;
      }
      return this.cartItems.reduce((acc, item) => acc + (item.product.price * item.quantity), 0);
    }
    },
  methods: {
    removeFromCart(item) {
      const index = this.cart.items.findIndex(i => i.product.id === item.product.id);
      if (index !== -1) {
        this.cart.items.splice(index, 1);
}
    }
  }
}
</script>