import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      token: '',
      isAuthenticated: false
    },
    cart: {
      items: [],
    },
    isLoading: false
  },
  getters: {
    productQuantity: state => product => {
      const item = state.cart.items.find(i => i.product.id === product.id);
      return item ? item.quantity : 0;
    },
    cartItems: state => state.cart.items
  },
  mutations: {
    initializeStore(state) {
      const token = localStorage.getItem('token');
      const cart = localStorage.getItem('cart');
      state.user.token = token || '';
      state.user.isAuthenticated = !!token;
      state.cart.items = cart ? JSON.parse(cart) : [];
    },
    setToken(state, token) {
      state.user.token = token;
      state.user.isAuthenticated = true;
      localStorage.setItem('token', token);
    },
    removeToken(state) {
      state.user.token = '';
      state.user.isAuthenticated = false;
      localStorage.removeItem('token');
    },
    addToCart(state, item) {
        console.log('Current state of cart.items:', state.cart.items);
        console.log('Is array:', Array.isArray(state.cart.items));

        if (!Array.isArray(state.cart.items)) {
            console.error('cart.items is not an array:', state.cart.items);
            state.cart.items = [];  // Resetting to array if not already
        }

      const existingItemIndex = state.cart.items.findIndex(i => i.product.id === item.product.id);
      if (existingItemIndex !== -1) {
        state.cart.items[existingItemIndex].quantity += parseInt(item.quantity, 10);
      } else {
        state.cart.items.push({ ...item, quantity: parseInt(item.quantity, 10) });
      }
      localStorage.setItem('cart', JSON.stringify(state.cart.items));
    },
    removeFromCart(state, productID) {
      state.cart.items = state.cart.items.filter(i => i.product.id !== productID);
      localStorage.setItem('cart', JSON.stringify(state.cart.items));
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    clearCart(state) {
      state.cart.items = [];
      localStorage.setItem('cart', JSON.stringify(state.cart.items));
    },
  },
  actions: {
    initializeStore({ commit }) {
      commit('initializeStore');
    },
    addToCart({ commit }, item) {
      commit('addToCart', item);
    },
    removeFromCart({ commit }, productID) {
      commit('removeFromCart', productID);
    },
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    removeToken({ commit }) {
      commit('removeToken');
    },
    setIsLoading({ commit }, status) {
      commit('setIsLoading', status);
    },
    clearCart({ commit }) {
      commit('clearCart');
    }
  },
  modules: {
  }
});
